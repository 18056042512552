import React from "react";
import { HowToStyled, BulletPoint } from "./style";
import one from "../../images/1.svg";
import two from "../../images/2.svg";
import three from "../../images/3.svg";
import four from "../../images/4.svg";
import five from "../../images/5.svg";
import comoFunciona from "../../images/como_funciona.svg";
import Zoom from "react-reveal/Zoom";

const HowTo = () => {
  return (
    <HowToStyled id="comoFunciona">
      <Zoom duration={1500}>
        <div className="title">
          <img src={comoFunciona} alt="¿Cómo funciona?" />
        </div>
        <BulletPoint>
          <img src={one} alt="one" />
          <div className="one">
            <h3>VISÍTANOS</h3>
            <p>
              Ven a hacer tu examen de la vista sin costo en nuestras
              sucursales.
            </p>
          </div>
        </BulletPoint>
        <BulletPoint>
          <img src={two} alt="two" />
          <div className="two">
            <h3>ESCOGE</h3>
            <p>
              Selecciona entre los 40+ modelos por cada tipo de armazón{" "}
              <span>(Clásicos o Premium)</span>.
            </p>
          </div>
        </BulletPoint>
        <BulletPoint>
          <img src={three} alt="three" />
          <div className="three">
            <h3>AGREGA</h3>
            <p>
              Protección de luz azul o tratamiento fotocromático – tú escoges.
            </p>
          </div>
        </BulletPoint>
        <BulletPoint>
          <img src={four} alt="four" />
          <div className="four">
            <h3>APARTA</h3>
            <p>Déjanos el 50% para mandar a fabricar tus lentes. </p>
          </div>
        </BulletPoint>
        <BulletPoint>
          <img src={five} alt="five" />
          <div className="five">
            <h3>RECÍBELOS</h3>
            <p>
              En 3-4 días (máximo 7) recoge tus lentes y disfruta ver bien,
              viéndote bien.{" "}
            </p>
          </div>
        </BulletPoint>
      </Zoom>
    </HowToStyled>
  );
};

export default HowTo;
