import React from "react";
import { ProgresivosStyled } from "./style";
import progresivos from "../../images/progresivos_desktop.svg";
import Card from 'react-bootstrap/Card';

const Progresivos = () => {
  return (
    <ProgresivosStyled>
        <img src={progresivos} alt="progresivos" className="title"/>
        <Card border="light" className="textCard" >
          <Card.Body>
            Este precio incluye armazón y graduación, se puede agregar
            antirreflejante por $400 adicionales. Si requieres tratamiento
            protección contra luz azul o tratamiento fotocromático lo cotizaremos
            por separado.
          </Card.Body>
        </Card>
    </ProgresivosStyled>
  );
};

export default Progresivos;
