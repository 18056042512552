import styled from "styled-components";

export const HomepageStyled = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  height: auto;
  width: auto;
  background-color: #69b5ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 50px;

  img {
    width: 800px;
    margin-bottom: 45px;

    @media (max-width: 1840px) {
      width: 600px;
    }

    @media (max-width: 1520px) {
      width: 550px;
    }
    @media (max-width: 620px) {
      width: 450px;
    }
    @media (max-width: 520px) {
      width: 350px;
    }
    @media (max-width: 480px) {
      width: 300px;
    }

    @media (max-height: 820px) {
      width: 320px;
    }

    /* @media (max-height: 620px) {
      width: 380px;
    }

    @media (max-height: 550px) {
      width: 320px;
    } */
  }

  button {
    color: #f06a54;
    background-color: #f06a54;
    border: 1px solid #f06a54;
    font-size: 24px;
    padding: 12px 32px;
    cursor: pointer;
    border-radius: 10px;

    @media (max-width: 720px) {
      font-size: 22px;
    }
    @media (max-width: 620px) {
      font-size: 20px;
    }
    @media (max-width: 520px) {
      font-size: 18px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-height: 820px) {
      font-size: 22px;
      padding: 10px 28px;
    }
    @media (max-height: 620px) {
      font-size: 20px;
      padding: 8px 24px;
    }
    @media (max-height: 550px) {
      font-size: 16px;
      padding: 6px 16px;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
`;
