import styled from 'styled-components';

export const HowToStyled = styled.div`
    background-color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    overflow: hidden;
    
    .title>img{
        margin-bottom: 40px;
       
        @media (max-width: 720px) {
            width: 500px;
        }
        @media (max-width: 520px) {
            width: 400px;
        }
        @media (max-width: 420px) {
            width: 350px;
        }
    }

    h2{
        font-size: 72px;
        font-family: 'gt_pressurabold';
        text-align: center;
        @media (max-width: 1220px) {
                font-size: 64px;
        }
        @media (max-width: 720px) {
                font-size: 64px;
        }
        @media (max-width: 620px) {
            font-size: 56px; 
        }
        @media (max-width: 520px) {
            font-size: 48px;
        }
        @media (max-width: 480px) {
            font-size: 42px;
        }  
    }
`;

export const BulletPoint = styled.div`

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-bottom: 10px;

img{
    margin-right: 20px;
    height: 80px;
    @media (max-width: 720px) {
            width: 45px;
            margin-right: 10px;
        }  
    @media (max-width: 620px) {
            width: 40px;
            margin-right: 5px;
        }
    @media (max-width: 520px) {
            
        }  
}
    .one{
            background-color: rgba(137,197,253,20%); 
    }
    .two{
            background-color: rgba(137,197,253,40%);    
    }
    .three{
            background-color: rgba(137,197,253,60%);    
    }
    .four{
            background-color: rgba(137,197,253,80%);    
    }
    .five{
            background-color: rgba(137,197,253,100%);    
    }

    div{
        border-radius: 100px;
        width: 65vw;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 4px 40px;

        @media (max-width: 620px) {
                height: 120px;
                border-radius: 50px;
                width: 80vw;
            } 

        @media (max-width: 480px) {
            padding: 2px 35px;
        } 
        
        h3{
            font-size: 32px;
            font-family: 'gt_pressurabold';
            margin: 0;
            padding: 0;
            @media (max-width: 1220px) {
                font-size: 28px;
        }
            @media (max-width: 720px) {
                font-size: 24px;
            } 
            @media (max-width: 520px) {
                font-size: 22px;
            }
            @media (max-width: 420px) {
                font-size: 20px;
            }  
        }

        p{
            font-size: 20px;
            margin: 0;
            padding: 0;
            @media (max-width: 1220px) {
                font-size: 18px;
        }
            @media (max-width: 720px) {
                font-size: 18px;
            }
            @media (max-width: 620px) {
                font-size: 17px;
            }
            @media (max-width: 520px) {
                font-size: 16px;
            }
            @media (max-width: 420px) {
                font-size: 14px;
            }  
            span{
                font-size: 20px;
                @media (max-width: 1220px) {
                    font-size: 18px;
                }   
                @media (max-width: 720px) {
                font-size: 16px;
                }
                @media (max-width: 620px) {
                    font-size: 14px;
                } 
            }

        }
    }

`;