import styled from "styled-components";

export const FloatingCTAWrapper = styled.button`
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #25d366;
  border: none;

  a{
      color: white;
  }

  svg{
    padding: 3px;
  }
`;
