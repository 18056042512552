import styled from "styled-components";

export const ClassicsStyled = styled.div`
    min-height: 50vh;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    overflow: hidden;

    @media (max-width: 1020px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const Info = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1020px) {
            width: auto;
            height: 35vh;
            margin: 10px 0;
        }

    img{
            width: 250px;
            margin-bottom: 20px;
            @media (max-width: 1420px) {
                width: 350px;
            }
            @media (max-width: 720px) {
                width: 300px;
            }
            @media (max-width: 620px) {
                width: 280px;
            }
            @media (max-width: 520px) {
                width: 250px;
            }
            @media (max-width: 480px) {
                width: 220px;
            }    
        }
        
        p{
            color: black;
            margin: 0;
            padding: 0;
            width: 35vw;
            line-height: 48px;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
            @media (max-width: 1420px) {
                font-size: 22px;
            }
            @media (max-width: 1020px) {
            width: 90vw;
            }
        }

        .cardText{
            width: 35vw;  
            background-color: transparent; 
            margin-bottom: 20px;
            text-align: center;

            @media (max-width: 1020px) {
                width: 80vw;  

                .carousel{
                    width: 80vw;
                }
            }
        }
    `;

    export const Gallery = styled.div`
        width: 35vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 50%;
            height: 100%;
            object-fit: cover;
        }

        @media (max-width: 1020px) {
            width: 80vw;
        }
    `;