import React from 'react';
import { GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import { MapComponentWraper } from './style';

const containerStyle = {
  width: '350px',
  height: '300px'
};


const MapComponent = ({lat,lng}) => {
  
  const center = {
    lat: lat,
    lng: lng
  };

  return  (
    <MapComponentWraper>

    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker 
          position={center}
          />
        <></>
      </GoogleMap>
    </LoadScript>
  </MapComponentWraper>
) 
//: <></>
};

export default React.memo(MapComponent)
//export default MapComponent
