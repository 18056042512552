import React, { useState, useEffect } from "react";
import { StoreStyles, LeftSection, RightSection } from "./style";
import { ImWhatsapp } from "react-icons/im";
import sanityClient from "../../client";
import { useParams } from "react-router-dom";
import MapComponent from '../../components/MapComponent'
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Store = () => {
  const [storeData, setStoreData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title, 
          slug,
          address,
          week,
          saturday,
          sunday,
          telephone,
          lat,
          lng,
          image{
            ...,
          },
      }`,
        { slug }
      )
      .then((data) => setStoreData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!storeData) return <div>Cargando...</div>;

  const altText = storeData?.image?.alt
  const storeImage = storeData?.image?.image

  return (
    <StoreStyles>
      <LeftSection>
        <h2>{storeData.title}</h2>
        <p>{storeData.address}</p>
        <ul>
          <li>
            <strong>Lunes a Viernes:</strong> {storeData.week}
          </li>
          <li>
            <strong>Sábados:</strong> {storeData.saturday}{" "}
          </li>
          <li>
            <strong>Domingos:</strong> {storeData.sunday}
          </li>
        </ul>
        <button>
          <a
            href={`https://wa.me/52${storeData.telephone}`}
            target="_blank"
            rel="noreferrer"
          >
            <ImWhatsapp style={{ width: "2em" }}/>
            AGENDA UNA CITA
          </a>
        </button>

        <MapComponent
          lat={storeData?.lat}
          lng={storeData?.lng}
          
        />
      </LeftSection>
      <RightSection className="thumbnail">
        <img src={urlFor(storeImage)} alt={altText} />
      </RightSection>
    </StoreStyles>
  );
};

export default Store;
