import React from 'react';
import Monofocales from '../Monofocales';
import Progresivos from '../Progresivos';
import { PricesStyled, Grid } from './style';
import precios from '../../images/precios.svg';
import Zoom from "react-reveal/Zoom";


const Prices = () => {
  return (
  <PricesStyled>
    <Zoom duration={1500}>
      <div>
        <img src={precios} alt="precios"/>
      </div>
      <Grid>
          <Monofocales />
          <Progresivos />
      </Grid>
    </Zoom>
  </PricesStyled>)
};

export default Prices;
