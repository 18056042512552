import './App.css';
import { BrowserRouter as Router,  Route,  Routes} from 'react-router-dom';   
import Landing from './pages/Landing';
import Privacy from './pages/Privacy';
import Store from './pages/Store';
import FAQ from './pages/FAQ';
import Layout from './components/Layout';
import TermsConditions from './pages/TermsConditions';
import Warranty from './pages/Warranty';
import ScrollToTop from './hoc/ScrollToTop';
import MobileMenu from './components/MobileMenu';
import FloatingCTA from './components/FloatingCTA';

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <ScrollToTop />
          <FloatingCTA />
          <Routes>
            <Route path="/" exact  element={<Landing />} />
            <Route path="/aviso-de-privacidad" exact  element={<Privacy />} /> 
            <Route path="/terminos-y-condiciones" exact  element={<TermsConditions />} />
            <Route path="/preguntas-frecuentes" exact  element={<FAQ />} />
            <Route path="/politica-de-garantias" exact  element={<Warranty />} />
            <Route path="/tiendas/:slug" exact  element={<Store />} />  
            <Route path="/menu" exact  element={<MobileMenu />} /> 
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
