import styled from "styled-components";

export const PricesStyled = styled.div`
    background-color: #69B5FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    overflow: hidden;
    @media (max-width: 1020px) {
           flex-direction: column;
        }
`;

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 1020px) {
           flex-direction: column;
           align-items: flex-start;
        }
`;