import styled from "styled-components";

export const WarrantyStyled = styled.div`
    background-color: white;
    height: auto;
    padding: 30px 50px 25px;
    overflow: hidden;
    
    h2{
        font-size: 48px;
        font-family: 'gt_pressurabold';
        margin: 0 0 20px 0;
    }
`

export const Section =  styled.div`

    h3{
        font-size: 32px;
        margin: 0;
        text-transform: uppercase;
        font-family: 'gt_pressurabold';
    }

    p{
        font-size: 18px;
        line-height: 28px;
    }

    table,th,tr,td{
        width: 50vw;
        border: 1px solid black
    }

    strong{
        font-family: 'gt_pressurabold';
    }
`;