import styled from 'styled-components';
import tiendas_back from '../../images/tiendas_back.jpg';

export const AllStoresWrapper = styled.div`
    font-family: 'gt_pressurabold';
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
    background-image: url(${tiendas_back});
    height: auto; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    overflow: hidden;

    @media (max-width: 520px) {
            justify-content: flex-start;
            align-items: center;
        }
    h2 {
        font-size: 72px;
        margin: 0 0 20px;
        padding: 0;
        align-items: center;
    } 

`;

export const StoreList = styled.ul`
    color: black;
    font-weight: bold;
    list-style: none;
    font-size: 38px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a{
        text-decoration: none;
        color: black;
        text-transform: uppercase;
    }

    a:hover{
        color: #F16A54;
    }

`;

export const ListItem = styled.li`
        font-weight: bold;
        margin: 8px 0 ;
        align-items: center;

`;