import React from "react";
import { FAQStyled, Section } from "./style";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";

const FAQ = () => {
  return (
    <FAQStyled>
      <h2>PREGUNTAS FRECUENTES</h2>
      <Section>
        <h3>Productos y Precios</h3>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h4>¿Qué precio tienen sus lentes y que incluyen?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Manejamos precios distintos para lentes Monofocales y para
                lentes Progresivos/Bifocales. Dentro de cada uno de estos
                tenemos dos niveles de precio en armazones (Clásicos y Premium)
                y dos precios para cada tipo de armazón según el nivel de
                graduación (menos de 3 dioptrías o más de 3 dioptrías). En
                Monofocales nuestros precios incluyen armazón, graduación y
                antirreflejante. Los Clásicos de menos de 3 dioptrías cuestan
                $499 y de más de 3 dioptrías $899. Los Premium de menos de 3
                dioptrías cuestan $899 y de más de 3 dioptrías $1,299. En
                Bifocales o Progresivos nuestros precios incluyen armazón y
                graduación. Los Clásicos de menos de 3 dioptrías cuestan $899 y
                de más de 3 dioptrías $1,699. Los Premium de menos de 3
                dioptrías cuestan $1,299 y de más de 3 dioptrías $1,999. En los
                lentes Bifocales o Progresivos se puede agregar antirreflejante
                por $400 adicionales.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h4>
                ¿Cuál es la diferencia entre armazones Clásicos y Premium?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Principalmente la calidad de los armazones: Los Clásicos son
                armazones de gama media. La definición perfecta de lentes
                buenos, bonitos y a precios increíbles. Los Clásicos incluyen un
                estuche de fleje. Los Premium son armazones de gama alta. Su
                calidad es comparable con la de marcas de lujo pero a un precio
                muchísimo menor. Los Premium incluyen un estuche rígido.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h4>
                ¿Cuál es la diferencia entre los lentes Monofocales y los
                Progresivos?{" "}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Los Monofocales ayudan a corregir solo un problema de la vista:
                puede ser miopía (problema para ver de lejos) o hipermetropía
                (para ver de cerca). Adicionalmente en los monofocales se puede
                corregir el astigmatismo. Los lentes Bifocales o Progresivos son
                para quienes requieren corregir la visión de lejos (miopía) y la
                de cerca (hipermetropía) en un mismo lente. Adicionalmente en
                los Bifocales o Progresivos se puede corregir el astigmatismo.
                La diferencia entre Bifocales y Progresivos es que los Bifocales
                llevan solo dos graduaciones (lejos y cerca) y los progresivos
                llevan tres graduaciones (cerca, la intermedia y lejos). Los
                Bifocales tienen una división clara entre las dos graduaciones.
                Los Progresivos no se ven divididos. Si tienes menos de 40 años,
                lo más probable es que no necesites lentes multifocales sino los
                monofocales. Las recetas de lentes progresivos tienen un dato
                extra que dice Adición (AD o ADD), así lo podrás identificar en
                la tuya.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h4>¿Si tengo graduación alta, cobran más?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Nuestros precios aplican en graduaciones de hasta 12 dioptrías.
                En graduaciones mayores a 12 dioptrías se cobran $1,000
                adicionales en los monofocales y $1,500 en los Bifocales o
                Progresivos.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h4>
                ¿Manejan lentes fotocromáticos (que se oscurecen con el sol)?{" "}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                ¡Si los manejamos! En Monofocales se puede agregar este
                tratamiento por $500 adicionales en graduaciones menores a 3
                dioptrías y $800 en graduaciones de más de 3 dioptrías. En
                Bifocales o Progresivos se pueden agregar y estos serían
                nuestros precios los cuales ya incluyen armazón, graduación
                antirreflejante y tratamiento fotocromático.
              </p>
              <table>
                <tr>
                  <th>Bifocales con Fotogray</th>
                  <th>Menos de 3</th>
                  <th>Más de 3</th>
                </tr>
                <tr>
                  <td>Clásicos</td>
                  <td>$ 1,999</td>
                  <td>$ 2,599</td>
                </tr>
                <tr>
                  <td>Premium</td>
                  <td>$ 2,399</td>
                  <td>$ 2,999 </td>
                </tr>
              </table>
              <br />
              <br />
              <table>
                <tr>
                  <th>Progresivos con Fotogray</th>
                  <th>Menos de 3</th>
                  <th>Más de 3</th>
                </tr>
                <tr>
                  <td>Clásicos</td>
                  <td>$ 2,299</td>
                  <td>$ 4,999</td>
                </tr>
                <tr>
                  <td>Premium</td>
                  <td>$ 2,699</td>
                  <td> $ 5,399</td>
                </tr>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <h4>¿Manejan lentes con protección de luz azul (Blue Light)?</h4>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>
                Si los manejamos en monofocales! En Monofocales se puede agregar
                este tratamiento por $500 adicionales en graduaciones menores a
                3 dioptrías y $800 en graduaciones de más de 3 dioptrías.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              {" "}
              <h4>¿Puedo comprar solo el armazón? </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Si se puede comprar solo armazón! Los armazones Clásicos cuestan
                $300 y los Premium $600.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <h4>¿Puedo comprar solo las micas? </h4>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>
                Para cuidar la calidad de nuestros trabajos solo hacemos micas
                para armazones comprados con nosotros.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <h4>¿Qué costo tiene reponer una mica?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>La reposición del costo de la mica es el siguiente: </p>
              <table>
                <tr>
                  <th>Monofocales</th>
                  <th>Menos de 3</th>
                  <th>Más de 3</th>
                </tr>
                <tr>
                  <td>Sin Extras</td>
                  <td>$100.00</td>
                  <td>$250.00</td>
                </tr>
                <tr>
                  <td>Con Extras</td>
                  <td>$250.00</td>
                  <td>$500.00</td>
                </tr>
              </table>
              <br />
              <br />
              <table>
                <tr>
                  <th>Progresivos</th>
                  <th>Menos de 3</th>
                  <th>Más de 3</th>
                </tr>
                <tr>
                  <td>Sin AR</td>
                  <td>$250.00</td>
                  <td>$500.00</td>
                </tr>
                <tr>
                  <td>Con AR</td>
                  <td>$500.00</td>
                  <td>$750.00</td>
                </tr>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <h4>¿Manejan lentes de contacto? </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Por ahora no manejamos lente de contacto. Solo armazones
                oftálmicos con graduación.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Section>
      <Section>
        <h3>Entregas y garantías </h3>
        <Accordion flush>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <h4>¿Cuánto tiempo tardan en entregar los lentes?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Hacemos entregas una vez por semana a cada tienda desde nuestro
                taller por lo que dependiendo el día de compra tarda en promedio
                3 a 4 días y un máximo de 7 días. Para trabajos Progresivos con
                graduación de más de 3 dioptrías a veces puede demorar hasta 10
                días.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              <h4>¿Qué garantía tienen mis lentes? </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Nuestros trabajos cuentan con garantía tanto del armazón, como
                de adaptación a la graduación. Para conocer con más detalle
                nuestra garantía haz click{" "}
                <Link to="/politica-de-garantias">aquí</Link>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Section>
      <Section>
        <h3>Forma de pago y facturación</h3>
        <Accordion flush>
          <Accordion.Item eventKey="12">
            <Accordion.Header>
              <h4>¿Aceptan apartado de los lentes?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Si! Puedes apartar tus lentes y los mandamos a fabricar
                cubriendo el 50% del costo. El resto lo puedes liquidar cuando
                recojas tus lentes. Una vez que están tus lentes en tienda
                tienes hasta 3 meses para recogerlos para evitar perder tu
                depósito de apartado.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>
              <h4>¿Qué formas de pago aceptan?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Puedes pagar directamente en tienda tanto en efectivo como con
                cualquier tarjeta de crédito. Además puedes pagar con
                transferencia electrónica.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>
              <h4>¿Pueden dar factura por la compra de mis lentes?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Si! Podemos dar factura con los requisitos que nos pidas. Solo
                avísanos que requieres factura dentro del mes de tu compra y con
                gusto la elaboramos en un lapso de 3 a 5 días.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Section>
      <Section>
        <h3>Examen de la vista</h3>
        <Accordion flush>
          <Accordion.Item eventKey="15">
            <Accordion.Header>
              <h4>¿Hacen ustedes el examen de la vista?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Si! En las tiendas tenemos equipos de última tecnología y
                especialistas para hacer los exámenes de la vista.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>
              {" "}
              <h4>¿Qué costo tiene el examen de la vista?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                El examen de la vista de completamente gratis! Sin costo y sin
                compromiso.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="17">
            <Accordion.Header>
              {" "}
              <h4>
                ¿Si traigo mi receta con mi graduación de otro lugar pueden
                ustedes hacerme mis lentes?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>
                Si los podemos hacer aunque en este caso no podemos dar la
                garantía de adaptación a la graduación.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="18">
            <Accordion.Header>
              <h4>¿Existen graduaciones que no puedan surtir? </h4>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>
                Si existen algunas graduaciones muy altas que no podemos surtir
                pero no te preocupes, nosotros te diremos en tienda si fuera el
                caso.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="19">
            <Accordion.Header>
              <h4>¿Puedo agendar cita para examen de la vista?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Claro! Y nos dará mucho gusto poder atenderte. Puedes agendar tu
                cita <a href="https://wa.me/527757509543">aquí</a>{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="20">
            <Accordion.Header>
              <h4>¿Es necesario agendar cita para que me hagan el examen?</h4>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>
                No es necesario pero para poder darte un mejor servicio siempre
                es más conveniente agendar cita. Puedes agendar cita{" "}
                <a href="https://wa.me/527757509543">aquí</a>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Section>
      <Section>
        <h3>Tiendas</h3>
        <Accordion flush>
          <Accordion.Item eventKey="21">
            <Accordion.Header>
              <h4>¿Dónde están ubicadas sus tiendas?</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Actualmente tenemos dos tiendas: <br />
                <strong>Tulancingo:</strong>{" "}
                <a href="https://goo.gl/maps/npaB7GAHPzdns6Gp9">
                  Libertad 304, Col Centro Tulancingo, Libertad 304, Col. Centro
                  Tulancingo, Hidalgo, C.P. 43600
                </a>
                <br /> <strong>Tizayuca:</strong>{" "}
                <a href="https://goo.gl/maps/PjxyuEtRFAZFtcpB6">
                  Álvaro Obregón 15 Col. Atempa, Tizayuca Hidalgo, C.P. 43800
                </a>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="22">
            <Accordion.Header>
              <h4>¿Cuál es su horario de atención? </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Nuestras tiendas están en un horario de Lunes a Viernes de 11 am
                a 7 pm y Sábados de 11 am a 5 pm.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="23">
            <Accordion.Header>
              <h4>¿Cuál es el teléfono de sus tiendas? </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Tulancingo: 775-750-9543 <br /> Tizayuca: 55-4768-2957
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="24">
            <Accordion.Header>
              <h4>¿Manejan todos los modelos de la página en las tiendas? </h4>
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <p>
                Aunque hay muchos modelos que ambas tiendas manejan, cada tienda
                tiene una colección diferente y constantemente estamos renovando
                los modelos disponibles.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Section>
    </FAQStyled>
  );
};

export default FAQ;
