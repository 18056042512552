import styled from "styled-components";

export const ProductsStyled = styled.div`
    background-color: #C3E2FF;
    height: auto;
    color: black;
    padding: 20px;
    overflow: hidden;
    @media (max-width: 1020px) {
            padding: 40px;
        }
`;

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    @media (max-width: 1020px) {
            flex-direction: column;
        }
    
`


export const Description = styled.div`
    height: auto;
    padding: 50px 50px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1020px) {
            padding: 10px;
        }
    
    div>img{
        margin-bottom: 40px;
        @media (max-width: 520px) {
            width: 300px;
        }
    }
    
    h2{
        font-size: 64px;
        padding: 50px 0 0 0;
        font-family: 'gt_pressurabold';
        margin: 10px 0;
        margin-bottom: 20px;
        @media (max-width: 1420px) {
            font-size: 56px;
        }
        @media (max-width: 720px) {
            font-size: 48px;
        }
        @media (max-width: 620px) {
            //font-size: 64px;
        }
        @media (max-width: 520px) {
            //font-size: 64px;
        }
        @media (max-width: 480px) {
            //font-size: 64px;
        }
    }

    p{
        font-size: 24px;
        margin: 0;
        margin-bottom: 20px;
        text-align: center;

        @media (max-width: 1420px) {
            font-size: 24px;
        }
        @media (max-width: 720px) {
            font-size: 22px;
            line-height: 48px;
        }
        @media (max-width: 520px) {
            font-size: 20px;
        }
    }   
`;

