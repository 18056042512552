import styled from "styled-components";

export const FAQStyled = styled.div`
    height: auto;
    padding: 30px 50px 25px;
    overflow: hidden;
    h2 {
        font-size: 48px;
        font-family: 'gt_pressurabold';
        margin: 0;
    }
`;

export const Section = styled.div`

ul{
    list-style: none;
    margin: 0;
    padding: 0;

    li>table,th,tr,td{
        width: 50vw;
        border: 1px solid black
    }
}

h3{
    font-size: 32px;
    text-decoration: underline;
    font-family: 'gt_pressurabold';
    text-transform: uppercase;
    margin: 20px;
}

h4{
    font-size: 20px;
    font-family: 'gt_pressurabold';
    text-transform: uppercase;
}

p{
    font-size: 18px;
    line-height: 28px;
}
`;