import styled from "styled-components";

export const AdvantagesStyled = styled.div`
    height: auto;
    color: white;
    background-color: #F16A54;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0;
    overflow: hidden;

    .mobile{
        display: none;
    }

    div>img{
        margin-bottom: 40px;
    }

     @media (max-width: 720px) {
        
         .mobile{
             display: flex;
             img{
                 width: 300px;
             }
         }
         .desktop{
             display: none;;
         }
    
    }
        

    h2{
        font-size: 72px;
        margin-bottom: 80px;
        font-family: 'gt_pressurabold';
        text-align: center;
        @media (max-width: 720px) {
            font-size: 64px;
            margin-bottom: 60px;
        }
        @media (max-width: 620px) {
            font-size: 56px; 
            margin-bottom: 40px;
        }
        @media (max-width: 520px) {
            font-size: 48px;
        }
        @media (max-width: 480px) {
            font-size: 42px;
        }

    }
`;

export const BulletPoints = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    margin-bottom: 50px;

    @media (max-width: 1220px){
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 620px) {
        grid-template-columns: 1fr;
    }
   // grid-auto-rows: minmax(100px, auto);
`;

export const Circles = styled.div`
 border-radius: 50%;
 background-color: white;
 border: 2px solid black;
 height: 320px;
 width: 320px;
 display: inline-block;
 color: black;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 .flatIcon{
        width: 40px;
        margin: 0;
        padding: 0;
    }

 /* @media (max-width: 1020px) {
        height: 340px;
        width: 340px;
    } */
    @media (max-width: 720px) {
        height: 300px;
        width: 300px;
    }
 

 h3{
     font-size: 40px;
     font-family: 'gt_pressurabold';
     text-transform: uppercase;
     margin: 0;
     padding: 0;
     text-align: center;
     margin-bottom: 10px;

    @media (max-width: 1020px) {
        font-size: 42px;
    }
    @media (max-width: 720px) {
        font-size: 38px;
    }
    @media (max-width: 620px) {
        font-size: 36px; 
    }
    
 }

 p{
    font-size: 18px;
    width: 18em;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    text-align: center;
    line-height: 32px;
    @media (max-width: 1020px) {
        font-size: 18px;
    }
    @media (max-width: 720px) {
        font-size: 16px;
    }
    @media (max-width: 620px) {
        font-size: 16px;
    }
 }
`;