import React from "react";
import { ProductsStyled, Description, Grid } from "./styles";
import Classics from "../Classics";
import Premium from "../Premium";
import productos from "../../images/productos.svg";
import Zoom from "react-reveal/Zoom";

function Products() {
  return (
    <ProductsStyled id="productos">
      <Zoom duration={1500}>
        <Description>
          <div>
            <img src={productos} alt="productos" />
          </div>
          <p>
            Para hacer la vida más fácil clasificamos nuestros armazones en dos:
            Clásicos y Premium.
          </p>
        </Description>
        <Grid>
          <Classics />
          <Premium />
        </Grid>
      </Zoom>
    </ProductsStyled>
  );
}

export default Products;
