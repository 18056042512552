import React, { useState, useEffect } from "react";
import { PremiumStyled, Info, Gallery } from "./style";
import premium from "../../images/premium.svg";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import sanityClient from "../../client";
//import imageUrlBuilder from "@sanity/image-url";

// const builder = imageUrlBuilder(sanityClient);

// function urlFor(source) {
//   return builder.image(source);
// }

const Premium = () => {
  const [allPhotos, setAllPhotos] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "premium"]{
        title,
        image{
          "imageUrl": image.asset->url,
          ...,
        },

      }`
      )
      .then((data) => setAllPhotos(data))
      .catch(console.error);
  }, []);

  return (
    <PremiumStyled>
      <Info>
        <img src={premium} alt="premium" />
        <Card border="light" className="cardText">
          <Card.Body>
            Lentes de gama alta. Su calidad es comparable con marcas de lujo a
            un precio muchísimo menor.
          </Card.Body>
        </Card>
      </Info>
      <Gallery>
        <Carousel className="carousel">
          {allPhotos ? (
            allPhotos.map(({ image, title }) => {
              let altText = image?.alt;
              return (
                <Carousel.Item key={title}>
                  <img
                    src={image?.imageUrl}
                    alt={altText}
                    className="d-block w-100"
                  />
                </Carousel.Item>
              );
            })
          ) : (
            <div>Cargando</div>
          )}
        </Carousel>
      </Gallery>
    </PremiumStyled>
  );
};

export default Premium;
