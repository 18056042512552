import styled from "styled-components";

export const TermsConditionsStyled = styled.div`
    height: auto;
    padding: 30px 50px 25px;
    overflow: hidden;
    h2{
        font-family: 'gt_pressurabold';
        font-size: 32px;
        text-transform: uppercase;
    }

`;

export const Section = styled.div`


`;