import styled from "styled-components";

export const PrivacyStyled = styled.div`
    height: auto;
    background-color: white;
    color: black;
    padding: 30px 50px 25px;
    overflow: hidden;

    h2{
        font-family: 'gt_pressurabold';
        font-size: 32px;
        text-transform: uppercase;
    }
`;

export const Section = styled.div`

    .underline{
        text-decoration: underline;
    }

    h3{
        font-family: 'gt_pressurabold';
        text-transform: uppercase;
    }

`;