import React, { useState } from "react";
import Logo from "../../images/DR.LENTE_Logo.svg";
import {
  HeaderWrapper,
  MenuWrapper,
  MobileMenuWrapper
} from "./styles";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi'

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <HeaderWrapper>
      <div>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <MenuWrapper className="visible">
        <li>
          <a href="/#sobre">Nosotros</a>
        </li>
        <li>
          <a href="/#productos">Productos/Precios</a>
        </li>
        <li>
          <a href="/#comoFunciona">¿Cómo funciona?</a>
        </li>
        <li>
          <a href="/#tiendas">Tiendas</a>
        </li>
        <li>
          <a href="/#contacto">Contacto</a>
        </li>
      </MenuWrapper>
      <MobileMenuWrapper>
               <Link to={!mobileMenu ? `/menu`: `/` } onClick={() => setMobileMenu(!mobileMenu)}>
                    <GiHamburgerMenu size={"40"} style={{textDecoration: "none"}}/>
               </Link>
           </MobileMenuWrapper>
    </HeaderWrapper>
  );
};

export default Header;
