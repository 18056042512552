import React from 'react';
import About from '../About';
import CTA from '../../components/CTA';
import AllStores from '../AllStores';
import HowTo from '../HowTo';
import Advantages from '../Advantages';
import Homepage from '../Homepage';
import Products from '../Products';
import Prices from '../Prices';

const Landing = () => {
    return (
        <div>
            <Homepage />
            <About />
            <Advantages />
            <Products />
            <Prices />
            <HowTo />
            <CTA />
            <AllStores />
        </div>
    )
}

export default Landing;
