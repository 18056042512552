import React from "react";
import { AboutStyled } from "./styles";
import Zoom from "react-reveal/Zoom";
import nosotros from "../../images/nosotros.svg";

const About = () => {
  return (
    <AboutStyled id="sobre">
      <Zoom duration={1500}>
        <div>
          <img src={nosotros} alt="nosotros" />
        </div>
        <p>
          Creemos que todos tienen derecho a{" "}
          <strong>ver bien, viéndose bien</strong>.
          <br />
          <br />
          Somos una empresa mexicana que busca{" "}
          <strong>dar el mejor precio en lentes</strong> pero siempre cuidando
          la calidad de nuestros productos.
          <br />
          <br />
          Nos enorgullece poder hacer que{" "}
          <strong>cada día más personas vean mejor</strong>.
          <br />
          <br />
          Creemos que la vida debe ser divertida y{" "}
          <strong>con buena vista se disfruta más</strong>.
        </p>
      </Zoom>
    </AboutStyled>
  );
};

export default About;
