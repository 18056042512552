import styled from "styled-components";

export const HeaderWrapper = styled.header`
    height: 7.5vh; 
    width: 100vw;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    overflow: hidden;
    margin: 5px 0;

    @media (max-width: 1180px) {
            height: 5vh;
        }

    div>a>img{
        width: 160px;

        @media (max-width: 1180px) {
            width: 120px;;
        }
    }

`;

export const MenuWrapper = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'gt_pressurabold';
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;

    @media (max-width: 1180px) {
        font-size: 18px;
    }

    @media (max-width: 930px) {
        display: none;
    }

    li {
        list-style: none;
        margin: 16px 16px 0 16px;

        a{
            text-decoration: none;
            color: black;
        }

        a:hover{
            color: #F06A54;
        }
    }
`;

export const MobileMenuWrapper= styled.div`
      @media (min-width: 930px) {
        display: none;
        color: black;
        a{
            color: black;
        }
    }
`;