import styled from "styled-components";

export const StoreStyles = styled.div`
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    overflow: hidden;
`;

export const LeftSection = styled.div`
    width: 40vw;
    height: auto;
    padding-left: 40px;
    @media (max-width: 1024px) {
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-left: 0;
            margin: 10px;
        }
    h2{
        font-family: 'gt_pressurabold';
        font-size: 72px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: 1024px) {
            font-size: 64px;
        }
    }

    p{
        width: 25vw;
        font-size: 24px;
        @media (max-width: 1024px) {
            width: 90vw;
            text-align: center;
            font-size: 20px;
        }
    }

    button{
        margin: 20px 0 20px;
        color: #F06A54;
        background-color: transparent;
        border: 1px solid #F06A54 ;
        font-size: 22px;
        padding: 12px 24px;
        cursor: pointer;
        background-color: white;
        border-radius: 10px;
        a{
            color:#F06A54;
            text-decoration: none;
            
        }
        a:hover{
            color: #5582AE;
;
        }
    }

    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 22px;

        @media (max-width: 1024px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        li{
            margin: 8px 0;
        }
    }
`;


export const RightSection = styled.div`
    width: 60vw;
    height: auto;
    padding: 0;
    margin: 0;
    @media (max-width: 1024px) {
            display: none;
        }

    .thumbnail{
        @media (max-width: 1024px) {
            display: none;
        }
    }
    img{
        width:100%;
        height:100%;
        object-fit: cover;
    }
`