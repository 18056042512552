import React from "react";
import {
  FooterWrapper,
  SectionOne,
  SectionTwo,
  Button,
  Column,
} from "./styles";
import { ImWhatsapp } from "react-icons/im";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterWrapper id="contacto">
      <SectionOne>
        <Column>
          <h4>CONTÁCTANOS</h4>
          <a href="mailto:hola@drlente.com">hola@drlente.com</a>
          <p>775-750-9543</p>
        </Column>
        <Column>
          <h4>SÍGUENOS</h4>
          <a href="https://www.facebook.com/drlente" target="_blank" rel="noreferrer">Facebook</a>
          <a href="https://www.instagram.com/doctor.lente/" target="_blank" rel="noreferrer">
            Instagram
          </a>
        </Column>
        <Column>
          <h4>EXTRAS</h4>
          <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
          <Link to="/politica-de-garantias">Politica de garantías</Link>
        </Column>
        <Button>
          <a href="https://wa.me/525532418056" target="_blank" rel="noreferrer">
            <ImWhatsapp style={{ width: "2em" }} />
            <h4>COTIZA TUS LENTES</h4>
          </a>
        </Button>
      </SectionOne>
      <hr />
      <SectionTwo>
        <p className="desktop">
          © Dr.Lente 2022{" "}
          <Link to="/terminos-y-condiciones">Términos y condiciones</Link> |{" "}
          <Link to="/aviso-de-privacidad">Aviso de Privacidad</Link>
        </p>
        <p className="mobile">
          <p>© Dr.Lente 2022</p><br />
          <Link to="/terminos-y-condiciones">Términos y condiciones</Link><br />
          <Link to="/aviso-de-privacidad">Aviso de Privacidad</Link>
        </p>
      </SectionTwo>
    </FooterWrapper>
  );
};

export default Footer;
