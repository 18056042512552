import styled from 'styled-components';

export const CTAWrapper =  styled.div`
    height: auto; 
    color: #F06A54;
    background-color: #C3E2FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:60px;
    overflow: hidden;
`;

export const CTATitle = styled.h2`
    font-size: 96px;
    font-family: 'gt_pressurabold';
    @media (max-width: 1020px) {
        font-size: 72px;
    }
    @media (max-width: 720px) {
        font-size: 64px;
    }
    @media (max-width: 660px) {
        font-size: 56px;
    }
    @media (max-width: 520px) {
        text-align: center;
    }
    @media (max-width: 480px) {
        //font-size: 16px;
    }
        

`;

export const CTAButton = styled.button`
    color: #F06A54;
    background-color: transparent;
    border: 1px solid #F06A54 ;
    font-size: 24px;
    padding: 16px 32px;
    cursor: pointer;
    border-radius: 10px;

    @media (max-width: 1020px) {
        font-size: 20px;
    }
    @media (max-width: 720px) {
        font-size: 18px;
    }

    a{
        text-decoration: none;
        color: #F06A54;
    }

    a:hover{
        color: #5582AE;
    }
`;