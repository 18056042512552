import React from "react";
import { AdvantagesStyled, BulletPoints, Circles } from "./style";
import { ImPriceTags } from "react-icons/im";
//import { BsEmojiSunglasses } from "react-icons/bs";
//import { FaRegThumbsUp, FaRegLaughWink } from "react-icons/fa";
import porque from "../../images/por_que.svg";
import porqueMovil from '../../images/por_que_movil_1.svg'
import Zoom from "react-reveal/Zoom";
import eyeChart from '../../images/eye-chart.png';
import eyewear from '../../images/eyewear.png';
import warranty from '../../images/warranty.png';

const Advantages = () => {
  return (
    <AdvantagesStyled id="ventajas">
      <Zoom duration={1500}>
        <div className="desktop" >
          <img src={porque} alt="¿Por qué Dr.Lente?" />
        </div>
        <div className="mobile">
          <img src={porqueMovil} alt="¿Por qué Dr.Lente?" />
        </div>
        <BulletPoints>
          <Circles>
            <h3>PRECIOS INCREÍBLES</h3>
            <p>
              Desde $499 ya con todo, <br />
              sin trucos ni extras ocultos.{" "}
            </p>
            <ImPriceTags size={"40"} />
          </Circles>
          <Circles>
            <h3>
              Garantía <br />
              Dr. Lente
            </h3>
            <p>
              Nos aseguramos que tus lentes te quedan bien y ves de maravilla.{" "}
            </p>
            {/* <BsEmojiSunglasses size={"40"} /> */}
            <img src={warranty} alt="eyewear" className="flatIcon" />
          </Circles>
          <Circles>
            <h3>Examen de la vista gratis</h3>
            <p>
              Por computadora, <br />
              sin costo ni compromiso.{" "}
            </p>
            {/* <FaRegThumbsUp size={"40"} /> */}
            <img src={eyeChart} alt="eyewear" className="flatIcon" />
          </Circles>
          <Circles>
            <h3>Armazones padrísimos</h3>
            <p> Te van a encantar y constantemente traemos nuevos modelos.</p>
            {/* <FaRegLaughWink size={"40"} /> */}
            <img src={eyewear} alt="eyewear" className="flatIcon"/>
          </Circles>
        </BulletPoints>
      </Zoom>
    </AdvantagesStyled>
  );
};

export default Advantages;
