import React from 'react';
import { HomepageStyled } from './styles';
import homepage from '../../images/homepage.svg';
import bar from '../../images/bar.svg';
import lentes from '../../images/lentes_desde.svg'

const Homepage = () => {
    return (
        <HomepageStyled>
            <img src={homepage} alt="Dr.Lente" className='logo' />
            <img src={bar} alt="bar" />
            <img src={lentes} alt="lentes" />
            <button>
                <a href="https://wa.me/525532418056" target="_blank" rel="noreferrer">
                    HAZ UNA CITA
                </a>
            </button>
        </HomepageStyled>
    )
}

export default Homepage
