import styled from "styled-components";

export const ProgresivosStyled = styled.div`
    background-color: transparent;
    height: auto;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
    .title{
        width: 40vw;
        @media (max-width: 1020px) {
            width: 80vw;
        }
    }

    p{
         width: 40vw;
         text-align: center;
     }

     .textCard{
        width: 40vw;
        height: 140px; 
        background-color: transparent;
        margin-top: 40px;

        @media (max-width: 1020px) {
            width: 80vw;
            height: auto;
        }
     }
`;