import styled from "styled-components";


export const MobileMenuWrapper =  styled.ul`
    background-color: white;
    color: black;
    font-family: 'gt_pressurabold';
    text-transform: uppercase;
    list-style: none;
    margin: 0;
    padding: 0 20px;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;

    a{
        color: black;
        text-decoration: none;
        list-style: none;
        font-size: 32px; 
    }
`;