import React from 'react';
import { MobileMenuWrapper } from './style';

const MobileMenu = () => {
  return (
  <MobileMenuWrapper>
        <li><a href='/#sobre'>Nosotros</a></li>
        <li><a href='/#productos'>Productos/Precios</a></li>
        <li><a href='/#comoFunciona'>¿Cómo funciona?</a></li>
        <li><a href='/#tiendas'>Tiendas</a></li>
        <li><a href="/#contacto">Contacto</a></li>
  </MobileMenuWrapper>)
};

export default MobileMenu;
