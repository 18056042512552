import React, { useState, useEffect }  from 'react';
import { AllStoresWrapper, StoreList, ListItem } from './styles'; 
import { Link } from 'react-router-dom';
import sanityClient from '../../client';
import Zoom from "react-reveal/Zoom";
import tiendas_titulo from '../../images/tiendas_titulo.svg'

const AllStores = () => {

    const [allStoresData, setAllStoresData] = useState(null);

    useEffect(()=>{
        sanityClient.fetch(
            `*[_type == "store"]{
                title, 
                slug,
                mainImage{
                    asset->{
                        _id,
                        url,
                    }
                }
            }`
        )
        .then((data) => setAllStoresData(data) )
        .catch(console.error)
    }, [])

    return (
        <AllStoresWrapper id="tiendas" >
           <Zoom duration={1500}>
                <div>
                    <img src={tiendas_titulo} alt="Tiendas" />
                </div>
                <div>
                {allStoresData && 
                    allStoresData.map((store) => (
                    <StoreList
                        key={store.title}
                    >
                        <ListItem>
                            <Link to={'/tiendas/'+store.slug.current} key={store.slug.current}>
                                {store.title}
                            </Link>
                        </ListItem>
                    </StoreList>
                    ))
                } 
                </div>
            </Zoom>
        </AllStoresWrapper>
    )
}

export default AllStores
