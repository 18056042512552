import React from "react";
import { FloatingCTAWrapper } from "./styles";
import { ImWhatsapp } from "react-icons/im";

const FloatingCTA = () => {
  return (
    <FloatingCTAWrapper>
      <a href="https://wa.me/525532418056" target="_blank" rel="noreferrer">
        <ImWhatsapp size={28} />
      </a>
    </FloatingCTAWrapper>
  );
};

export default FloatingCTA;
