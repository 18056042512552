import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background-color: #5582ae;
  color: white;
  min-height: 25vh;
  height: auto;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 720px) {
    padding-bottom: 20px;
  }

  hr {
    width: 95vw;
    margin-bottom: 20px;
  }
`;

export const SectionOne = styled.div`
  margin: 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95vw;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Column = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
  @media (max-width: 720px) {
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  h4 {
    margin-bottom: 8px;
    font-family: "gt_pressurabold";
    font-size: 20px;
    @media (max-width: 1020px) {
      font-size: 16px;
    }
    @media (max-width: 920px) {
      font-size: 14px;
    }
    @media (max-width: 720px) {
      font-size: 18px;
    }
  }

  a {
    margin: 0;
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 8px;
    text-decoration: none;
    @media (max-width: 1020px) {
      font-size: 16px;
    }
    @media (max-width: 920px) {
      font-size: 14px;
    }
    @media (max-width: 720px) {
      font-size: 18px;
    }
  }
  a:hover {
    color: #f06a54;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  @media (max-width: 720px) {
    margin: 20px;
  }
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: #25d366;
    border: 2px solid #25d366;
    padding: 8px;
    border-radius: 10px;
    text-decoration: none;

    h4 {
      margin: 10px;
      font-family: "gt_pressurabold";
      font-size: 20px;
      @media (max-width: 1020px) {
        font-size: 16px;
      }
      @media (max-width: 920px) {
        font-size: 14px;
      }
      @media (max-width: 720px) {
        font-size: 18px;
      }
    }
  }
`;

export const SectionTwo = styled.div`
  margin-left: 35px;
  width: 95vw;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;

  .mobile {
    display: none;
  }

  @media (max-width: 720px) {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;

    .mobile {
      display: flex;
      flex-direction: column;
    }
    .desktop {
      display: none;
    }
  }

  p {
    font-size: 18px;
    margin: 0;
    padding: 0;
    @media (max-width: 1020px) {
      font-size: 16px;
    }
    @media (max-width: 920px) {
      font-size: 14px;
    }
    @media (max-width: 720px) {
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: white;
      @media (max-width: 1020px) {
        font-size: 16px;
      }
      @media (max-width: 920px) {
        font-size: 14px;
      }
      @media (max-width: 720px) {
        font-size: 16px;
      }
    }

    a:hover {
      color: #f06a54;
    }
  }
`;
