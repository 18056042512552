import React from "react";
import { WarrantyStyled, Section } from "./style";

const Warranty = () => {
  return (
    <WarrantyStyled>
      <h2>POLÍTICA DE GARANTÍAS</h2>
      <Section>
        <h3>ARMAZONES</h3>
        <p>
          Todos nuestros armazones cuentan con una garantía de 30 días naturales
          posteriores a su compra por defecto o vicio en su funcionamiento. Si
          no hubiera en existencia el mismo modelo para su reemplazo se cambiará
          por algún otro armazón del mismo precio. En caso de presentar un
          defecto o vicio en su funcionamiento después de 30 y antes de 90 días
          naturales, se le ofrecerá el cliente un descuento del 30% en cualquier
          armazón que compre en sustitución del armazón que sufrió el
          desperfecto. La garantía no cubre daños ocasionados por manipulación
          inadecuada, el uso en condiciones anormales o el desgaste natural
          causado por su uso. Si su armazón presenta daños causados por mal uso
          o uso distinto, tales como rayones a las lentes y/o el armazón se
          considerará como evidencia de maltrato y no aplicará la garantía.
        </p>
      </Section>
      <Section>
        <h3>GRADUACIÓN</h3>
        <p>
          Se le permitirá al cliente hacer 1 (UN) sólo cambio de graduación sin
          costo cuando este cambio no haya sido por error en el trabajo
          realizado en el taller. Esto aplica únicamente para graduaciones de
          menos de 6 dioptrías. En caso de que el cambio de graduación sea hacia
          abajo (menos dioptrías) no se hará reembolso por diferencias en
          precio. En caso de que el cambio de graduación sea hacia arriba (más
          dioptrías) se cobrará la diferencia en precio. Si el cliente requiere
          algún cambio posterior al primer cambio se le cobrará de acuerdo a la
          tabla “Precios para cambio de micas”. Para hacer válida la garantía,
          el cliente deberá presentar el producto en perfectas condiciones y su
          nota de compra en la sucursal donde fue adquirido. Si el producto
          tiene cualquier desperfecto, no se hará válida la garantía.
        </p>
      </Section>
      <Section>
        <h3>CAMBIO DE MODELO</h3>
        <p>
          El cliente podrá solicitar el cambio de modelo dentro de los primeros
          7 días naturales posteriores a recibir sus lentes graduados. Sin
          embargo, aplicará el costo para cambio de micas de la tabla “Precios
          para cambio de micas” y solo se podrá cambiar por un armazón del mismo
          nivel de precio.{" "}
        </p>
      </Section>
      <Section>
        <strong>
          Las garantías aplican a partir de la fecha de recepción del cliente de
          sus anteojos graduados{" "}
        </strong>
        <br />
        <br />
        <strong>Precio por mica</strong>
        <table>
          <tr>
            <th>Monofocales</th>
            <th>Menos de 3</th>
            <th>Más de 3</th>
          </tr>
          <tr>
            <td>Sin Extra</td>
            <td>$100</td>
            <td>$250</td>
          </tr>
          <tr>
            <td>Con Extra</td>
            <td>$250</td>
            <td>$500</td>
          </tr>
        </table>
        <br />
        <br />
        <table>
          <tr>
            <th>Progresivos</th>
            <th>Menos de 3</th>
            <th>Más de 3</th>
          </tr>
          <tr>
            <td>Sin AR</td>
            <td>$250</td>
            <td>$500</td>
          </tr>
          <tr>
            <td>Con AR</td>
            <td>$500</td>
            <td>$750</td>
          </tr>
        </table>
        <br />
        <br />
      </Section>
      <Section>
        <h3>Política de Entregas</h3>
        <p>
          Se solicitará al cliente como mínimo un 50% del precio total para
          poder enviar a fabricar los lentes con su graduación personalizada.
          Una vez en tienda se avisará al cliente y tiene un máximo de 90 días
          naturales para pasar a recoger sus lentes y liquidar su saldo
          pendiente. En caso de que el cliente no recoja sus lentes dentro de
          los siguientes 90 días naturales a la fecha en que se le notificó que estaban en
          tienda, Dr. Lente podrá quedarse con el anticipo y no requerirá
          entregarle ningún trabajo al cliente.{" "}
        </p>
      </Section>
    </WarrantyStyled>
  );
};

export default Warranty;
