import React from "react";
import { MonofocalesStyled } from "./style";
import monofocales from "../../images/monofocales_desktop.svg";
import Card from 'react-bootstrap/Card';


const Monofocales = () => {
  return (
    <MonofocalesStyled>
        <img src={monofocales} alt="monofocales" className="title" />
        <Card border="light" className="textCard">
          <Card.Body>
            Este precio incluye armazón, graduación y antirreflejante básico. Se
            puede agregar protección contra luz azul o tratamiento fotocromático
            por $500 adicionales para graduaciones menores a 3 dioptrías y $800
            para graduaciones mayores a 3 dioptrías.
          </Card.Body>
        </Card>
    </MonofocalesStyled>
  );
};

export default Monofocales;
