import React from "react";
import { CTAWrapper, CTAButton, CTATitle } from "./styles";
import Zoom from "react-reveal/Zoom";

const CTA = () => {
  return (
    <CTAWrapper>
      <Zoom duration={1500}>
        <CTATitle>VE BIEN, VIÉNDOTE BIEN</CTATitle>
        <CTAButton>
          <a href="https://wa.me/525532418056" target="_blank" rel="noreferrer">
            HAZ UNA CITA
          </a>
        </CTAButton>
      </Zoom>
    </CTAWrapper>
  );
};

export default CTA;
