import styled from "styled-components";

export const AboutStyled = styled.div`
  height: auto;
  color: black;
  background: white;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 520px) {
        margin: 50px 0;
        }

  div>img{
    margin-bottom: 40px;

    @media (max-width: 520px) {
        width: 300px;
        }
  }

  h2{   
      font-size: 64px;
      font-family: 'gt_pressurabold';
      margin: 0;
      text-align: center;
      @media (max-width: 720px) {
          font-size: 56px;
        }
  }

  p{  text-align: center;
      font-size: 24px;
      line-height: 38px;
      width: 50vw;
      @media (max-width: 720px) {
          font-size: 22px;
        }
       
        @media (max-width: 520px) {
          width: 80vw;
        }
       
  }
`;
