import React from "react";
import { PrivacyStyled, Section } from "./style";

const Privacy = () => {
  return (
    <div>
      <PrivacyStyled>
        <h2>
          AVISO DE PRIVACIDAD INTEGRAL PARA USUARIOS Y CLIENTES DE DR. LENTE
        </h2>
        <p>
          Para Doctor Lente es importante que nuestros clientes se sientan
          seguros y tranquilos sobre el uso de sus datos personales por lo que
          les recomendamos leer nuestro Aviso de Privacidad. La empresa
          responsable de proteger los datos personales que nos proporcionan
          nuestros clientes es Doctor Lente, S.A.P.I. de C.V. (en adelante
          "Doctor Lente" o "Nosotros"), con domicilio en Felix Cuevas 6 Piso 7
          Col. Del Valle, Alcaldía Benito Juarez, Ciudad de México C.P. 03200.
          Para contactarnos favor de marcar al teléfono 775-750-9543 o mandar un
          correo electrónico a la dirección hola@drlente.com.
        </p>
        <Section>
          <h3>¿Qué información recolectamos?</h3>
          <p>
            Los datos que recolectamos son aquellos que nos ayudan a brindarte
            un mejor servicio, tales como:
            <ul>
              <li>Datos de identificación (ejemplo, nombre, dirección).</li>
              <li>
                Datos de contacto (ejemplo, teléfono, correo electrónico).
              </li>
              <li>
                Datos relacionados con la salud (ejemplo, dioptrías,
                padecimientos médicos existentes).
              </li>
              <li>
                Datos financieros (ejemplo, número de cuenta bancaria y número
                de tarjeta de crédito).
              </li>
            </ul>
            Doctor Lente recaba datos personales sensibles de salud para diseñar
            los anteojos atendiendo tus necesidades médicas. A partir de lo
            anterior, obtenemos datos sobre tus antecedentes médicos o clínicos
            (ejemplo, diabetes, hipertensión, cirugías oculares) y aquellos
            contenidos en las recetas y diagnósticos oftalmológicos o de otras
            especialidades relacionadas con la vista. Sabemos la importancia de
            esta información, y estamos comprometidos a que tus datos sensibles
            serán tratados bajo los estándares y medidas más estrictas de
            seguridad que garanticen su confidencialidad.
            <br />
            <br />
            Es importante expresarte que en nuestras redes sociales y foros
            públicos de discusión no solicitamos ni compartimos datos
            personales.
            <br />
            <br />
            <span className="underline">
              Cámaras de videovigilancia en nuestras Tiendas
            </span>
            <br />
            <br />
            Recabamos audio y vídeo para tu seguridad y de las personas que nos
            visitan, y dicha información solo será utilizada para fines de
            prevención de perdidas en las mismas y en caso de que alguna
            autoridad facultada para ello lo requiera de manera directa.
          </p>
        </Section>
        <Section>
          <h3>¿Para qué fines recabamos tus datos personales?</h3>
          <p>
            En cumplimiento de la Ley, te avisamos que daremos tratamiento a tus
            datos personales únicamente para las siguientes finalidades:
            <ol>
              <li>
                Primarias que son necesarias para brindarte nuestros servicios:
                <ul>
                  <li>Agendar y realizar un examen de la vista;</li>
                  <li>Registrar tu pedido y darle seguimiento;</li>
                  <li>
                    Contactarte vía telefónica o mensaje (SMS o de correo
                    electrónico) para recordarte la fecha de tu examen de la
                    vista, así como comunicarte la fecha de entrega de tus
                    lentes;
                  </li>
                  <li>Diseñar y entregarte tus lentes;</li>
                  <li>
                    Informarte de cambios o nuevos productos o servicios que
                    estén relacionados con el producto que nos compraste;
                  </li>
                  <li>Atender tus comentarios y quejas;</li>
                  <li>Realizar cobros y reembolsos (en caso de que aplique)</li>
                  <li>Emitir una factura;</li>
                  <li>
                    Realizar las actividades necesarias para brindarle los
                    servicio y productos que nos solicites y cumplir con
                    nuestras obligaciones como proveedores.
                  </li>
                  <li>
                    Realizar programas de beneficios (en caso de que aplique);
                  </li>
                  <li>
                    Brindar seguridad a las personas que visitan nuestras
                    tiendas.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                Secundarias que no son esenciales para que recibas nuestros
                servicios, pero nos permite mejorar nuestra atención y
                productos:
                <ul>
                  <li>
                    Realizar encuestas o evaluaciones para medir la calidad en
                    el servicio que brindamos y mejorar tu experiencia en su
                    próxima adquisición de mercancía y/o servicios;
                  </li>
                  <li>
                    Realizar análisis, estudios de mercado y reportes de
                    nuestros productos y clientes, estudios de mercado,
                    registros sobre hábitos de consumo a través de herramientas
                    de captura automática de datos, intereses y comportamiento.
                  </li>
                  <li>
                    Enviarte anuncios, novedades, nuevos lanzamientos,
                    promociones y descuentos de nuestros productos.
                  </li>
                  <li>
                    Participar en redes sociales, chats y/o foros de discusión;
                  </li>
                  <li>
                    rticipar en eventos, trivias, concursos, rifas, juegos y
                    sorteos;
                  </li>
                </ul>
              </li>
            </ol>
            En caso de que no desees que utilicemos tus datos personales para
            alguna o todas las finalidades secundarias antes referidas, puedes
            contactarnos al whatsapp 775-750-9543 o mandar un correo electrónico
            a la dirección hola@drlente.com.
            <br />
            <br />
            El hecho que manifiestes tu oposición al uso de sus datos personales
            para fines secundarios no significa que te negaremos nuestros
            servicios.
          </p>
        </Section>
        <Section>
          <h3>¿Le vamos a transferir tus datos a alguien más?</h3>
          <p>
            No te preocupes, Doctor Lente solo transfiere datos para cumplir con
            (i) sus obligaciones contractuales contigo, en caso de que así sea
            necesario, como, por ejemplo, trasmisión de la información con
            proveedores de mensajeria o (ii) atender requerimientos de
            información debidamente fundados y motivados emitidos por
            autoridades mexicanas competentes.
          </p>
        </Section>
        <Section>
          <h3>¿Tu información está segura?</h3>
          <p>
            Nos tomamos muy en serio la seguridad de tus datos. Para compras en
            tienda, los protocolos de seguridad son igual de estrictos, ya que
            en todo momento podrás observar donde se encuentra tu medio de pago
            elegido, así como la información que se recabe del mismo es
            trasmitido de forma segura con el servidor de la institución
            financiera. Por el momento Doctor Lente no ofrece la opción de
            compra en línea.{" "}
          </p>
        </Section>
        <Section>
          <h3>¿Puedes actualizar tu información?</h3>
          <p>
            Puedes hacer la modificación por vía telefónica mandando un mensaje
            de Whatsapp al 775-750-9543 o mandar un correo electrónico a la
            dirección hola@drlente.com.
          </p>
        </Section>
        <Section>
          <h3>¿Eres menor de edad?</h3>
          <p>
            Tus papas o tutor(es) tendrán que enviar tu información enviando un
            mensaje de Whatsapp al 775-750-9543 o mandar un correo electrónico a
            la dirección hola@drlente.com.{" "}
          </p>
        </Section>
        <Section>
          <h3>
            ¿Puedo revocar mi consentimiento, así como acceder, rectificar
            ,cancelar u oponerme al uso de mis datos (derechos ARCO)?
          </h3>
          <p>
            Para ejercer los Derechos ARCO (Acceso, Rectificación, Corrección y
            Oposición), así como solicitar la revocación del consentimiento
            deberás informarlo enviando un mensaje de Whatsapp al 775-750-9543 o
            mandar un correo electrónico a la dirección hola@drlente.com. Es
            importante mencionarte, que en el proceso de atención a tu solicitud
            se considerará que el ejercicio de dichos derechos no obstaculice el
            cumplimiento de alguna Ley vigente o mandato judicial, o el
            cumplimiento de obligaciones contractuales con Doctor Lente. En ese
            sentido, es importante tener en cuenta que no en todos los casos
            será procedente tu solicitud o concluir el uso de sus datos
            personales de forma inmediata. Asimismo, la revocación de tu
            consentimiento para ciertas finalidades implica que no podamos
            seguir prestando el servicio que nos solicitaste.
          </p>
        </Section>
        <Section>
          <h3>¿Puedo limitar el uso o divulgación de mis datos personales?</h3>
          <p>
            Para limitar el uso y divulgación de su información personal, a fin
            de que tus datos personales no sean tratados para fines
            mercadotécnicos, publicitarios, de prospección comercial o para uso
            con finalidades secundarias por nuestra parte, de igual forma puedes
            solicitarlo enviando un mensaje de Whatsapp al 775-750-9543 o mandar
            un correo electrónico a la dirección hola@drlente.com.{" "}
          </p>
        </Section>
        <Section>
          <h3>¿Qué es eso de cookies?</h3>
          <p>
            Tu navegación en nuestro sitio web activa cookies. Las cookies son
            pequeñas piezas de información que son enviadas por el sitio web al
            navegador. Se almacenan en el disco duro de tu equipo y las
            utilizamos para identificar tus preferencias, dirección IP y lo que
            haces dentro de nuestra página. También nos permiten reconocerte al
            ingresar a nuestra página, detectar el ancho de banda y saber qué
            información puede ser de tu interés. La instalación, permanencia y
            existencia de las cookies en tu computadora depende de tu exclusiva
            voluntad y puede ser eliminada cuando así lo desees. Para saber cómo
            quitar las Cookies del sistema es necesario revisar la sección Ayuda
            ("Help") de tu navegador. Por ejemplo, en el caso de Google Chrome
            puedes consultar el siguiente enlace en:
            <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=es">
                https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=es
            </a>
          </p>
        </Section>
        <Section>
          <h3>Cambios al aviso de privacidad</h3>
          <p>
            El aviso de privacidad puede tener modificaciones, cambios o
            actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los productos o servicios que
            ofrecemos; de cambios en nuestro modelo de negocio, o por otras
            causas de momento en momento. Las actualizaciones que pueda tener
            este aviso de privacidad se darán a conocer en a través de nuestro
            sitio web: <a href="http://www.drlente.com">www.drlente.com</a>
          </p>
        </Section>
        <Section>
          <h3>Derechos de los titulares</h3>
          <p>
            Si consideras que Doctor Lente ha vulnerado tu derecho a la
            protección de datos personales, así como los derechos ARCO, puedes
            acudir al Instituto Nacional de Transparencia, Acceso a la
            Información y Protección de Datos Personales “INAI”, cuya página web
            es <a href="http://inicio.ifai.org.mx/SitePages/ifai.aspx">http://inicio.ifai.org.mx/SitePages/ifai.aspx</a>
          </p>
        </Section>
        <p className="underline">
          Última fecha de modificación: 02 de enero de 2022.
        </p>
      </PrivacyStyled>
    </div>
  );
};

export default Privacy;
